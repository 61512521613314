.selectize {
  &.form-control {
    padding: 0;
  }

  &.selectize-dropdown {
    height: auto;
  }

  &.selectize-control {
    &.single {
      border: initial;

      &.selectize-sm {
        .selectize-input {
          padding: 5px 8px;
        }
      }

      .selectize-input {
        border: 2px solid #ccc;
        background: none;
        padding: 7px 8px;

        &.disabled {
          background-color: #eeeeee;
        }
      }
    }
  }
}

.has-error {
  .selectize.selectize-control.single .selectize-input {
    border: 2px solid #f83600;
  }
}
