@navbar-inverse-link-color: #fff;
@navbar-inverse-link-active-bg: transparent;
@navbar-link-active-border-width: 2px;

.navbar {
  box-shadow: 0 0 11px #444;
  border: 0;

  &.navbar-inverse {
    z-index: 3;

    .navbar-brand {
      overflow: hidden;

      & > img {
        height: 150px;
        margin-top: -55px;
      }
    }

    #gradient > .horizontal(@panel-single-color, @panel-single-color, 10%, 90%);

    .nav li {
      &.active a {

        border-bottom: @navbar-link-active-border-width solid rgba(255, 255, 255, 0.4);
        padding-top: @navbar-padding-vertical - @navbar-link-active-border-width;
        padding-bottom: @navbar-padding-vertical - @navbar-link-active-border-width;
      }
    }
  }

  @media (max-width: 1023px) {
    .navbar-brand {
      padding: 15px 5px;
    }
  }
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 0;
  }
}

.badge-success {
  background: @brand-success;
}

.container-fluid > .navbar-header {
  margin-right: 0;
}
