.modal {
  .modal-header {

    &.modal-info {
      color: #fff;
      border-bottom: 4px solid lighten(@brand-info-light, 15%);
      #gradient > .horizontal(@panel-single-color, @panel-single-color, 10%, 90%);
    }

    &.modal-primary {
      color: #fff;
      border-bottom: 4px solid lighten(@brand-primary, 10%);
      #gradient > .horizontal(@panel-single-color, @panel-single-color, 10%, 90%);
    }

    .modal-title {
      .fa {
        margin-right: 10px;
      }
    }

    button {
      &.close {
        color: #fff;
        opacity: 1;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .modal-content {
    .table-responsive {
      margin-bottom: 0;
    }
  }
}

.modal-body {
  .form-group {
    .file-name {
      line-height: 34px;
    }
  }

  .fmp-compare {
    .updated {
      background-color: #8fbc8f;
    }
  }
}
