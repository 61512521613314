.map {
  height: 400px;

  &.plot-map {
    height: 800px;
  }

  &.property-map {
    height: 495px;
  }

  &.big {
    height: auto;
    position: fixed;
    width: 100%;
    top: 50px;
    bottom: 0;
  }

  .leaflet-popup {
    a {
      &.btn {
        display: block;
        margin: 5px 0;
        padding: 3px;
        color: #fff;
      }
    }

    .leaflet-popup-content {
      word-break: break-all;
    }
  }
}

.new-map {
  height: 750px;
}

.leaflet-bottom {
  .leaflet-control-scale {
    &.leaflet-control {
      margin-bottom: 20px;
    }
  }
}

.leaflet-map-pane {
  z-index: 2 !important;
}

.leaflet-google-layer {
  z-index: 1 !important;
}

#side-map-container {
  .map {
    height: 600px;
  }
}

.ui-coordinates {
    position: absolute;
    bottom: 5px;
    left: 10px;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    z-index: 1000;
}

.plot-geo-label {
  font-size: 15px;
}

@media screen and (max-width: 533px) {
  .ui-coordinates {
    display: none;
  }
}

.leaflet-control-layers-group {
  > .leaflet-control-layers-group-label {
    background-color: #dedede;
    text-align: center;
  }
}

.direction-arrow {
  position: fixed;
  bottom: 20px;
  right: 20px;

  .top-triangle,
  .bottom-triangle {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }

  .top-triangle {
    border-bottom: 35px solid red;
  }

  .bottom-triangle {
    border-top: 35px solid blue;
  }
}
