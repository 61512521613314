.btn-group-justified {
  display: block;

  & > .btn {
    width: 100%;
    display: inherit;
  }

  & > .btn-group {
    width: 100%;
    display: inherit;

    .dropdown-menu {
      width: 100%;
      text-align: center;
    }
  }
}

.caret-toggle {
  position: relative;
  float: right;
  background: transparent none;
  border: 1px solid transparent;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.filter {
  table {
    tr {
      td {
        span:first-child {
          float: left;
        }

        span:last-child {
          float: right;
        }
      }
    }
  }
}

.user-add {
  margin-bottom: 5px;
}

.panel-footer {
  &.property {
    .button-save {
      @media (max-width: 767px) {
        float: right;
      }
    }
  }
}

.lookup {
  width: 72px;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
  }
}

#filter-form {
  #filter-save {
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    #filter-reset,
    #filter-submit,
    #filter-save {
      width: 100%;
      margin: 5px auto;
      padding: 10px;
    }
  }

  .button-apply {
    float: right;
  }
}

.buttons {
  .btn {
    width: 100%;

    &.js-modal {
      @media (max-width: 767px) {
        margin-top: 10px;
      }
    }
  }
}

#filtered-table {
  .button-top {
    .text {
      &-full {
        @media (max-width: 767px) {
          display: none;
        }
      }
      &-short {
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }

  &.col-md-3 {
    .button-top {
      .text {
        &-full {
          display: none;
        }
        &-short {
          @media (max-width: 1439px) {
            display: none;
          }
          @media (min-width: 1440px) {
            display: inherit;
          }
        }
      }

      @media (min-width: 1024px) {
        .btn-sm {
          padding: 5px 8px;
        }
      }
    }
  }

  &.col-md-6 {
    .button-top {
      .text {
        &-full {
          @media (max-width: 1439px) {
            display: none;
          }
        }
        &-short {
          @media (min-width: 768px) {
            display: inherit;
          }
          @media (min-width: 1440px) {
            display: none;
          }
        }
      }
    }
  }
}

.with-buttons {
  .button-top {
    .btn:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.add-property-bottom-row {
  margin-bottom: 10px;
  display: none;

  @media (max-width: 992px) {
    display: block;
  }

  @media (max-width: 425px) {
    .btn {
      width: 100%;
    }
  }
}

.add-property-top-btn {
  @media (max-width: 992px) {
    display: none;
  }
}

.top-btn {
  color: #68695f;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1000000000;
  display: none;
  outline: none;

  &:focus,
  &:hover {
    color: #68695f;
  }
}

.no-click {
  pointer-events: none;
}
