.form-horizontal {
  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
}

.form-control {
  padding: 5px 12px;
}

.filter-active {
  .filter-active-content {
    padding: 15px 15px 5px 15px;

    button {
      width: 100%;

      @media (max-width: 767px) {
        margin-top: 10px;
      }
    }
  }

  .select2-container--bootstrap {
    .select2-selection {
      font-size: 12px;
    }
  }

  .select2-container {
    .select2-selection--single {
      height: 28px;
    }
  }

  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        line-height: 28px;
      }

      .select2-selection__arrow {
        height: 28px;
      }
    }
  }
}

.form-horizontal {
  .select2-container--default {
    &.select2-container--focus {
      .select2-selection--multiple {
        border: 2px solid #ccc;
      }
    }
  }
  .select2-container--open {
    &.select2-container--below {
      .select2-selection--multiple {
        border: 2px solid #ccc;
      }
    }
  }
  .select2-selection {
    &.select2-selection--multiple {
      border: 2px solid #ccc;
    }
  }
}

.select2-container--open {
  &.select2-dropdown--below {
    .select2-selection--multiple {
      border: 2px solid #ccc;
      border-top: none;

      .select2-results__option {
        &.select2-results__message {
          color: #999;
        }
      }
    }
  }
}

.row-control {
  height: 34px;
  padding: 6px 12px;
}

.selector {
  &-date,
  &-time {
    padding: 0 5px;
  }
}

.minicolors-theme-greengold {
  .minicolors-swatch {
    top: 5px;
    left: 5px;
    width: 23px;
    height: 23px;
  }

  &.minicolors-position-right {
    .minicolors-swatch {
      left: auto;
      right: 5px;
      border-radius: 4px;

      .minicolors-swatch-color {
        border-radius: 4px;
      }
    }
  }
}

.color-box {
  display: inline-block;
  height: 20px;
  width: 50px;
}

.view-horizontal {
  .view-group {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;

    .view-label {
      text-align: right;
      margin-bottom: 0;
      padding-top: 7px;
    }

    .view-control {
      padding: 7px 12px;
      width: 100%;
    }
  }
}

.select2-results__option {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.panel-select-row {
  margin-top: 5px;

  .panel-select-label {
    margin-top: 6px;
  }

  .panel-select {
    padding: 0;
    line-height: 1;
    height: 28px;
  }
}

.selector-time {
  > .input-group-addon {
    cursor: pointer;
  }
}

.input-group {
  &.disabled {
    .input-group-addon {
      cursor: not-allowed !important;
    }
  }
}

.related-objects-table {
  .checkbox {
    padding-top: 0;

    @media screen and (max-width: 767px) {
      padding-top: 4px;
    }
  }
}

#activities-info-table {
  input {
    min-width: 65px;
  }

  select {
    min-width: 85px;
  }
}
.selectize-control .selectize .form-control .single {
  cursor: not-allowed !important;
}
.selectize.selectize-control.single .selectize-input.disabled {
  background-color: #eeeeee;
  opacity: 1;
}

#property_sale_comment {
  resize: none;
}
