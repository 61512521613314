.panel {
  box-shadow: 1px 1px 3px #aaa;
  border: 0;

  &.processes-panel,
  &.task-panel {
    margin-bottom: 0;

    &.panel-primary {
      > .panel-heading {
        + .panel-collapse {
          > .panel-body {
            border-top: none;
          }
        }
      }
    }
  }
}

&.panel-primary {
  > .panel-heading {
    + .panel-collapse {
      > .panel-body {
        border-top: none;
      }
    }
  }
}

.panel {
  > .panel-heading {
    color: #fff;
    min-height: @padding-base-vertical*4+@line-height-computed + 2;

    .fold-icon {
      margin-top: 4px;
      margin-right: -4px;
    }
    .panel-title {
      line-height: 22px;

      &.not-seen {
        > i {
          margin-right: 7px;
        }
      }

      &.plot-panel-title {
        line-height: 30px;

        .prev-plot {
          margin-right: 15px;
        }

        .next-plot {
          margin-left: 15px;
        }
      }
    }

    .overdue-alert {
      float: right;
      margin-top: 5px;
    }

    .lh-34 {
      line-height: 34px;
    }

    .toolbar {
      float: right;
      margin-top: -3px;
      .btn {
        .btn-sm();
      }
      .btn-default {
        background: rgba(255, 255, 255, 0.45);
        color: #fff;
        transition: background .5s;
        &:hover {
          background: rgba(255, 255, 255, 0.65);
        }
      }
    }

    &.with-buttons {
      @media (max-width: 374px){
        padding: 0 5px;
      }
      @media (min-width: 375px) {
        padding: 0 15px;
      }

      .panel-title {
        padding-top: 10px;
      }

      .button-top {
        padding-top: 7px;
      }
    }
  }

  .panel-body {
    h3 {
      &.hblock {
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
      }
    }
  }

  &-primary > .panel-heading {
    border-bottom: 4px solid lighten(@brand-primary, 10%);
    background-color: @panel-single-color;
  }
  &-success > .panel-heading {
    border-bottom: 4px solid lighten(@brand-success-light, 15%);
    #gradient > .horizontal(@brand-success, @brand-success-light, 10%, 90%);
  }
  &-danger > .panel-heading {
    border-bottom: 4px solid lighten(@brand-danger-light, 15%);
    #gradient > .horizontal(@panel-single-color, @panel-single-color, 10%, 90%);
  }
  &-info > .panel-heading {
    border-bottom: 4px solid lighten(@brand-info-light, 15%);
    #gradient > .horizontal(@panel-single-color, @panel-single-color, 10%, 90%);
  }
  &-warning > .panel-heading {
    border-bottom: 4px solid lighten(@brand-warning-light, 15%);
    #gradient > .horizontal(@brand-warning, @brand-warning-light, 10%, 90%);
  }
  &-default > .panel-heading {
    border-bottom: 4px solid #ccc;
    #gradient > .horizontal(#aaa, #ddd, 10%, 70%);
  }

  > .table {
    thead th {
      background: #f7f7f7;

      a {
        text-decoration: none;
        color: @gray-light;
      }
    }
  }

  &.panel-embedded {
    .panel-heading {
      padding: 0;

      .panel-title {
        padding: 10px 15px;

        @media screen and (max-width: 767px) {
          padding: 10px 0 10px 5px;
        }
      }

      .button-top {
        padding: 7px 5px;
        a {
          margin-left: 2px;
          margin-right: 2px;
        }
      }

      .select-top {
        padding-top: 2px;
      }
    }
  }

  .task-status {
    padding: 0 6px 3px 6px;
  }

  &-process-tasks {
    margin-top: 10px;

    .glyphicon {
      &-star {
        color: @brand-warning;

        &-empty {
          color: #b3b3b3;
        }
      }
    }
  }

  &.properties-panel {
    .panel-select {
      max-width: 170px;
      display: inline-block;
    }
  }
}
