@body-bg: #eee;
@font-family-base: 'Open Sans', sans-serif;

@brand-primary: #3a7bd5;
@brand-primary-light: #00B1D7;
@brand-success: #3CA55C;
@brand-info: #56B4D3;
@brand-info-light: #348F50;
@brand-warning: #F09819;
@brand-warning-light: #EDDE5D;
@brand-danger: #f83600;
@brand-danger-light: #fe8c00;
@brand-success: #3CA55C;
@brand-success-light: #B5AC49;

@panel-single-color: #68695F;

@panel-border-radius: @border-radius-small;

@alert-primary-bg: @brand-primary;
@alert-primary-border: @brand-primary-light;
@alert-primary-text: #fff;

@alert-success-bg: @brand-success;
@alert-success-border: @brand-success-light;
@alert-success-text: #fff;

@alert-warning-bg: @brand-warning;
@alert-warning-border: @brand-warning-light;
@alert-warning-text: #fff;

@alert-info-bg: @brand-info;
@alert-info-border: @brand-info-light;
@alert-info-text: #fff;

@alert-danger-bg: @brand-danger;
@alert-danger-border: @brand-danger-light;
@alert-danger-text: #fff;


@panel-default-border: @table-border-color;
@glypicon-success: @brand-success;
@glypicon-error: @brand-danger;
