.container-fluid {
  padding: 0 5px;
}

.container.container-content {
  margin-top: @navbar-height;
}

.container-content {
  > h1:first-child {
    margin-bottom: 25px;
    margin-top: 30px;
  }
}

.row {
  margin: 0 -5px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding: 0 5px;
}

.filter {
  padding: 0;

  .row {
    .input-sm {
      padding: 3px 6px;
    }
  }

  > tr td:first-child {
    text-align: left;
    color: #777777;
  }

  .filter-footer {
    margin: 0;
    padding: 5px 0;
    border-top: 1px solid #ADADAD;
  }

  .filter-wrapper {
    padding-left: 15px;
    padding-right: 15px;

    ul {
      list-style: none;
      padding-left: 15px;

      &.single {
        padding-left: 0;
        margin-top: 10px;
      }

      li {
        padding: 5px 0;

        .heading {
          padding: 10px 0 10px 20px;

          &:before {
            left: 5px;
          }
        }

        .col-xs-5 {
          line-height: 30px;
        }

        ul {
          padding-left: 20px;
          padding-right: 5px;
          margin-bottom: 0;
        }
      }
    }
  }

  .heading {
    position: relative;
    margin-bottom: .2em;
    padding: 10px 0 10px 15px;
    color: #777777;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.3rem;
    cursor: pointer;

    &:before {
      font-family: FontAwesome;
      content: "\f078";
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;
    }

    &.off {
      &:before {
        font-family: FontAwesome;
        content: "\f054";
        top: 53%;
      }
    }
  }
}

.animate-width {
  @media (max-width: 992px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 993px) {
    &.col-md-12 {
      padding-left: 40px;
      padding-right: 0;

      &.offset-right:not(.tasks-list) {
        padding-right: 40px;
      }
    }
  }
}

.side-collapse {
  float: left;
  white-space: nowrap;
  padding: 0;
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  position: absolute;
  z-index: 1;

  > .panel {
    position: relative;
    background-color: #f5f5f5;
    margin-bottom: 0;
  }

  .filter {
    visibility: hidden;
  }

  .panel-heading {
    &:hover {
      cursor: pointer;
    }

    .panel-title {
      span {
        display: none;
      }
      .fa {
        margin-left: -6px;
      }
    }

    .fold-icon {
      position: absolute;
      top: 0;
      right: 15%;
      color: #777777;
      height: 100%;
      width: 100%;

      .filter-arrow-icon {
        position: absolute;
        top: 50%;
        right: 40%;
      }
    }

    .arrow-left {
      display: none;
    }

    .arrow-right {
      display: block;
    }
  }
}

.side-collapse.in {
  width: 25%;
  max-width: 25%;
  position: static;

  > .panel {
    position: static;
    background-color: #fff;
  }

  .filter {
    visibility: visible;
  }

  .panel-heading {
    .panel-title {
      span {
        display: inline-block;
      }
      .fa {
        margin-left: 0;
      }
    }

    .fold-icon {
      position: static;
      color: #fff;
      width: auto;
      height: auto;

      .filter-arrow-icon {
        position: static;
      }
    }

    .arrow-left {
      display: block;
    }

    .arrow-right {
      display: none;
    }

    .panel-title {
      display: inherit;
    }
  }
}

.right-collapse {
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  position: absolute;
  z-index: 0;
  right: 5px;

  > .panel {
    position: relative;
    background-color: #f5f5f5;
    margin-bottom: 0;
  }

  .collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-control-layers,
  .leaflet-control-zoom {
    display: none;
  }

  .panel-heading {
    .panel-title {
      span {
        display: none;
      }
      .fa {
        margin-left: -6px;
      }
    }

    &:hover {
      cursor: pointer;
    }

    .fold-icon {
      position: absolute;
      top: 0;
      right: 15%;
      color: #777777;
      height: 100%;
      width: 100%;

      .filter-arrow-icon {
        position: absolute;
        top: 50%;
        right: 40%;

        .arrow-left {
          display: none;
        }

        .arrow-right {
          display: block;
        }
      }
    }
  }

  &.in {
    width: 50%;
    max-width: 50%;
    padding-left: 5px;

    .leaflet-control-layers,
    .leaflet-control-zoom {
      display: inherit;
    }

    .panel-heading {
      .panel-title {
        span {
          display: inline-block;
        }
      }

      .fold-icon {
        position: static;
        color: #fff;
        width: auto;
        height: auto;

        .filter-arrow-icon {
          position: static;

          .arrow-left {
            display: block;
          }

          .arrow-right {
            display: none;
          }
        }
      }
    }
  }
}

.big-left-padding {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
}

.side-toggle.show-plus .fa-minus {
  display: none;
}

.side-toggle.show-plus .fa-plus {
  display: block;
}

.side-toggle .fa-plus {
  display: none;
}

.side-toggle .fa-minus {
  display: block;
}

.big-left-padding .side-toggle {
  position: absolute;
  left: 15px;
}

.map-services {
  li {
    .glyphicon {
      display: inline-block;
      padding-right: 15px;

      &.glyphicon-ok-sign {
        color: @glypicon-success;
      }
    }
  }
  .service-form {
    display: none;
    padding-top: 15px;

    .form-group {
      label {
        font-weight: normal;
      }
    }
  }
  .has-error {
    .help-block {
      margin-bottom: 0;
    }
  }
}

.help-block {
  &.ajax-alert {
    margin-bottom: 0;

    .fa {
      color: initial;
    }

    .glyphicon {
      display: none;

      &.glyphicon-ok-sign {
        color: #228b22;
      }
    }
  }
}

.filter-add {
  margin-top: 5px;

  &:hover {
    cursor: pointer;
  }
}

.help-block {
  &.has-error {
    .message {
      color: #f83600;
    }
  }
}

.filters-selections {
  ul {
    font-size: 0;

    li {
      margin-left: 5px;

      &.badge {
        word-wrap: break-word;
        max-width: 100%;
        white-space: normal;
        padding-left: 7px;
        padding-right: 5px;
        margin-top: 10px;

        &:hover {
          cursor: pointer;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }
}

.tasks-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  vertical-align: top;
  min-height: 100%;

  @media screen and (max-width: 767px) {
    white-space: normal;
  }

  @media screen and (min-width: 768px) {
    overflow-y: hidden;
    .col-status-body.collapse {
      display: block;
    }
  }

  .col-status {
    width: 350px;
    display: inline-block;
    vertical-align: top;
    margin: 0 -2px;

    &-3 {
      width: 33.3333%;
    }

    &-4 {
      width: 25%;

      @media screen and (max-width: 1095px) {
        width: 350px;
      }
    }

    &-5 {
      width: 20%;

      @media screen and (max-width: 1299px) {
        width: 350px;
      }
    }

    @media screen and (min-width: 768px) {
      &.minimized {
        width: 50px;

        span {
          opacity: 0;
        }

        .col-status-body .no-tasks-text,
        .col-status-body .single-task,
        .collapse-all-btn {
          display: none;
        }

        .col-status-title {
          min-height: 43px;

          .collapse-column-horizontal-btn {
            right: 11px;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .col-status-title {
      padding: 0 5px;
      position: relative;

      .col-status-btn {
        color: black;
        position: absolute;
        top: 1px;
        right: 45px;
      }

      .collapse-column-horizontal-btn,
      .collapse-all-btn {
        font-size: 12px;
        outline: none;
        border: none;
        background: transparent;
        position: absolute;
        top: -4px;
        right: 15px;
      }

      .collapse-column-horizontal-btn {
        top: 4px;
        right: 35px;
      }
    }

    .col-status-body {
      min-height: 350px;

      @media screen and (min-width: 768px) {
        overflow-y: auto;
      }

      &.minimal {
        .panel-title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .ui-state-highlight {
      height: 65px;
      margin: 0 5px 20px 5px;
      border: 1px solid #3a7bd5;
      background: #6497de;
      opacity: 0.85;
    }

    .col-status-body {
      min-height: 350px;

      &.minimal {
        .panel-title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .ui-state-highlight {
      height: 65px;
      margin: 0 5px 20px 5px;
      border: 1px solid #3a7bd5;
      background: #6497de;
      opacity: 0.85;
    }
  }

  .single-task {
    position: relative;
    padding: 0 5px;

    &.ui-sortable-helper {
      opacity: 0.85;
    }

    .handle {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 30%;
      z-index: 10;
      display: none;

      .fa {
        font-size: 1.2em;
        position: relative;
        left: 20px;
        top: 13px;
      }
    }

    .panel-heading {
      background-color: #3c3c3c;
      cursor: move;

      .ui-handle {
        float: left;
        margin-top: 5px;
        margin-right: 10px;
      }
    }

    .tooltip {
      white-space: nowrap;

      &-arrow {
        border-left-color: #757575;
      }

      &-inner {
        background-color: #757575;
      }
    }

    *[data-toggle="tooltip"] {
      cursor: pointer;
    }

    .panel-title {
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
      cursor: pointer;

      &.full {
        white-space: pre-wrap !important;
        white-space: -moz-pre-wrap !important;
        white-space: -pre-wrap !important;
        white-space: -o-pre-wrap !important;
        word-wrap: break-word !important;
        overflow: visible !important;
      }

      &.minimized {
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
      }
    }

    .new-comment-box {
      float: right;
      margin-top: -2px;
      margin-left: 7px;
      width: 1.8em;
      height: 1.8em;
      line-height: 1.8em;

      i {
        font-size: 1.8em;
      }

      .fa-stack-text {
        color: #3c3c3c;
        font-size: 13px;
      }
    }

    .not-seen {
      font-weight: bold;
    }

    .related-title {
      margin-top: 20px;
    }
  }

  .no-tasks-text {
    text-align: center;
    margin-top: 50px;
  }
}

.task-comments-list {
  margin-bottom: 20px;

  .comment-row {
    margin-bottom: 10px;

    .details {
      display: table-cell;
      vertical-align: middle;

      ul {
        li {
          word-wrap: break-word;
          text-align: center;
          margin-bottom: 5px;

          &.author-name {
            font-weight: bold;
          }
          &.write-date {
            font-style: inherit;
          }
        }
      }
    }

    .comment-text {
      position: relative;
      top: auto;
      left: auto;
      display: block;
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-top: 10px;
      z-index: 1;

      .arrow {
        top: auto;
        margin-top: 8px;
      }
    }

    .new-comment-label {
      margin-bottom: 10px;

      &.pull-left {
        margin-right: 10px;
      }

      &.pull-right {
        margin-left: 10px;
      }
    }
  }
}

.comment-form-box {
  margin-top: 15px;

  @media (min-width: 768px) {
    .vertical-align {
      margin-top: 35px;
    }
  }
}

.processes-wrapper {
  overflow: auto;
  white-space: nowrap;

  .process-table {
    margin-bottom: 0;

    th {
      background: #f7f7f7;

      &.title {
        vertical-align: middle;
        text-align: center;

        &.draft-col {
          min-width: 90px;
        }

        &.cadastre-col {
          min-width: 200px;
        }
      }

      a {
        text-decoration: none;
        color: @gray-light;
      }
    }

    th, td {
      border: 2px solid #ddd;
    }

    tr {
      &.active {
        th, td {
          background-color: #81c193;
        }
      }

      &.active:hover {
        th, td {
          background-color: #81c193;
        }
      }

      &.group-title {
        background:#68695F;
        color: #fff;
        font-weight: bold;
      }
    }
    tbody {
      .process-row:hover {
        background-color: #81c193;
        th {
          background-color: #81c193 !important;
        }
      }

    }

    .color-indicator {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 3px;
      position: relative;
      top: 2px;
      border: 1px solid #000;
    }

    .has-sub-table {
      padding: 0;
      vertical-align: middle;

      table {
        width: 100%;
        margin-bottom: 0;

        &.collapsed {
          .forestry-header,
          tr:not(.forestry-row):not(:first-child) {
            display: none;
          }

          th {
            border-bottom: none;
          }

          .sub-header {
            text-align: left;
          }

          tr:nth-of-type(odd) {
            .sub-header {
              background: none;
            }
          }

          .forestry-row {
            th:not(.target),
            td:not(.target) {
              display: none;
            }
          }

          .target {
            border: none;
          }

          th.target {
            border-bottom: 2px solid #ddd;
          }
        }

        tr:first-child {
          th,
          td {
            border-top: none;
          }
        }

        tr:last-child {
          td {
            border-bottom: none;
          }
        }

        th,
        td {
          padding: 2px 5px;

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }

        .sub-header {
          cursor: pointer;
          padding: 5px 10px;
          vertical-align: middle;

          .glyphicon-star {
            color: #f09819;
          }
        }
      }
    }
  }
}

.forestry-sub-title {
  color: inherit;
}

.task-details-list {
  white-space: normal;
}

.geosearch {
  z-index: 1000 !important;
}

.modal-flash-wrapper {
  .alert {
    margin: 10px 10px;
  }
}

.time-line-row {
  .panel {
    margin-bottom: 10px;

    .nav-btn-col {
      padding-top: 7px;
      padding-right: 10px;

      a {
        margin-right: 5px;
      }
    }

    .time-line-table {
      th {
        cursor: pointer;

        &.active {
          .year-holder {
            display: inline-block;
            border: 1px solid #68695F;
            border-radius: 50%;
            padding: 3px 7px;
          }
        }
      }

      td {
        text-align: center;
        color: #fff;
        font-size: 20px;
        cursor: pointer;

        &.required {
          background: #ff9900;
        }

        &.recommended {
          background: #06f;
        }

        &.urgent {
          background: #ff6600;
        }
      }
    }
  }

  .time-line-legend {
    li {
      margin-left: 10px;

      &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 10px;
        position: relative;
        top: 4px;
      }

      &.legend-required {
        &:before {
          background: #ff9900;
        }
      }

      &.legend-recommended {
        &:before {
          background: #06f;
        }
      }

      &.legend-urgent {
        &:before {
          background: #ff6600;
        }
      }

      &.legend-manually {
        &:before {
          font-family: FontAwesome;
          content: '\f14b';
          font-size: 22px;
          top: 3px;
        }
      }
    }
  }
}

.loader-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .2);
  text-align: center;
}

.url-address {
  display: inline-block;
  width: 265px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 400px) {
    width: 145px;
  }
}

.property-edit-page {
  .collapse-header {
    cursor: pointer;

    .collapse-icon {
      font-size: 0.65em;
      position: relative;
      top: -2px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 992px) {
  .side-collapse {
    width: 100%;
    max-width: 100%;

    .panel-heading {
      .panel-title {
        .fa {
          margin-left: 0;
        }
      }
    }
  }

  .side-collapse.in {
    width: 100%;
    max-width: 100%;
  }

  .adjust-mobile-padding {
    padding-left: 0;
  }

  .side-collapse,
  .side-collapse.in {
    position: relative;

    .filter {
      visibility: visible;
    }

    > .panel {
      margin-bottom: 20px;
    }

    .panel-heading {
      .panel-title {
        display: inherit;

        span {
          display: inline-block;
        }
      }

      .arrow-left,
      .arrow-right {
        display: none;
      }

      .fold-icon {
        position: static;
        color: #fff;
        width: auto;
        height: auto;
      }
    }
  }

  .right-collapse {
    display: none;
  }
}

@media (min-width: 993px) {
  #filtered-table {
    &.col-md-3 {
      table {
        tr {
          th:nth-child(n+4):nth-child(-n+7),
          td:nth-child(n+4):nth-child(-n+7) {
            display: none;
          }
        }
      }
    }

    &.col-md-9 {
      padding-right: 0;

      &.offset-right:not(.tasks-list) {
        padding-right: 40px;
      }
    }

    &.col-md-6 {
      padding-left: 40px;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1300px;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 1500px;
  }
}

@media (min-width: 1800px) {
  .container {
    width: 1700px;
  }
}

@media screen and (max-width: 767px) {
  .container.container-content {
    margin-top: 0;
  }

  .quarter {
    padding-top: 10px;
    float: right;
  }
}

@media screen and (min-width: 768px) {
  .profile {
    .col-sm-6:nth-of-type(2) {
      padding-left: 30px;
    }
  }

  .vertical-align {
    display: flex;
    align-items: center;
  }
}
