.panel-footer {
  .pagination {
    float: right;
    margin: 0;
  }
  .pagination-summary {
    // btn height + padding + border
    line-height: @line-height-computed + @padding-base-vertical*2 + 2;
    margin: 0;
  }
}
