.table {
  &.table-striped {
    > tbody {
      > tr {
        &.ui-state-highlight {
          background-color: #fffadd;
        }
      }
    }
  }
  tr {
    .ui-handle {
      cursor: pointer;
      color: #B3B3B3;
      padding-left: 5px;
    }

   &.filters {
     .btn-group-justified {

     }
   }
  }
  th,
  td {
    &.col-min {
      width: 1%;
      white-space: nowrap;
    }
    &.col-first {
      width: 2.5%;
      white-space: nowrap;
    }
    &.break-word {
      word-break: break-all;
    }
    &.middle,
    &.col-middle {
      vertical-align: middle;
    }
    &.normal-space {
      white-space: normal;
    }
  }
}

.table-report {
  .table {
    > tbody {
      > tr {
        > td {
          max-width: 250px;
        }
      }
    }
  }
}

.table-select {
  margin-bottom: 5px;

  .plot-select {
    width: auto;
    margin-left: 10px;
  }

  @media (min-width: 768px) {
    .select-options {
      float: left;
    }

    select {
      width: auto;
    }
  }
}

#filter {
  table {
    margin-bottom: 0;

    tr {
      &.heading-2 {
        td:first-child {
          padding-left: 20px;
        }

        &.sub-heading {
          >td:first-child {
            font-weight: bold;
            color: #777777;
          }
          > td:hover {
            cursor: pointer;
          }
        }
      }
      &.heading {
        >td:first-child {
          font-weight: bold;
          color: #777777;
        }
        > td:hover {
          cursor: pointer;
        }
      }

      td {
        border: none;

        &.field-spacing {
          width: 10px;
          text-align: center;
        }
      }
    }
  }

  tbody {
    & + tbody {
      border-top: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .table-mobile {
    border: 0;

    thead, tfoot {
      display: none;
    }
    tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #ddd;
    }

    &.table-form {
      th {
        display: block;

        label {
          width: 100%;
          text-align: left;
        }
      }

      .help-block {
        text-align: left;
      }
    }

    td {
      display: block;
      text-align: right;
      font-size: 13px;

      &:before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: bold;
      }

      &:not(.checkbox-col):after {
        content: '\00a0'
      }

      &.checkbox-col {
        &:before {
          padding-top: 5px;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      &.group-title {
        background: #50bf72;
      }
    }

    &.audit {
      tr {
        td {
          text-align: left;

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .filter-mobile {
    > tbody {
      > tr {
        border-bottom: none;

        &.heading {
          > td:last-child {
            width: 100%;
            font-weight: bold;

          }
        }

        &.sub-heading {
          > td:first-child {
            width: 100%;
            font-weight: bold;
          }
        }

        > td {
          display: inline-block;
          text-align: right;
          font-size: 13px;
          float: left;

          &:first-child {
            width: 35%;
            text-align: left;
            text-transform: uppercase;
          }

          &:last-child {
            width: 65%;
          }
        }
      }
    }
  }

  .layer-head {
    background: rgba(80, 191, 114, 0.4)
  }

  #layers-table {
    .select2-container {
      top: 18px;
    }
  }

  .remove-form-row {
    float: left;
  }
}

#filtered-table {
  .table {
    > tbody {
      > tr {
        &.highlight {
          > td {
            background: @brand-warning-light;
          }
        }
      }
    }
  }
}

.table-tasks {
  &.table-striped {
    > tbody {
      > tr {
        &:first-child {
          background-color: @panel-single-color;
        }
        &.list-sub-title {
          background-color: @panel-single-color;
        }
      }
    }
  }

  &.table-hover {
    > tbody {
      > tr {
        &.list-sub-title:hover {
          background-color: @panel-single-color;
        }
      }
    }
  }

  .list-sub-title {
    background: @panel-single-color;
    color: #fff;

    > th {
      > .glyphicon {
        padding-right: 10px;
      }

      > .property-link {
        color: #fff;
        word-break: break-word;
      }
    }
  }
}

.table-label {
  color: #777;
}

.status-col {
  min-width: 135px;
}

.due-date-col {
  width: 250px;
}

.overdue {
  color: @brand-danger;
}

.color-col {
  min-width: 130px;
}

.color-indicator {
  display: inline-block;
  width: 23px;
  height: 23px;
  border-radius: 3px;
}

.table-form {
  margin-bottom: 0;

  tbody > tr > th {
    text-align: center;
    vertical-align: middle;

    label {
      margin-bottom: 0;
    }
  }
}

.species-col {
  min-width: 125px;
}

.calc-col {
  min-width: 100px;
}

.age-col,
.name-col,
.tsd-col,
.height-col,
.stocking-col,
.diameter-col,
.basal-col,
.volume-col,
.trees-col {
  min-width: 75px;
}

@media screen and (max-width: 1510px) {
  #layers-table {
    .form-control {
      padding-left: 3px;
      padding-right: 3px;
    }

    .volume-col {
      min-width: 65px;
    }

    .species-col {
      min-width: 115px;
    }

    .calc-col {
      min-width: 80px;
    }

    .name-col,
    .age-col,
    .tsd-col,
    .height-col,
    .stocking-col,
    .diameter-col,
    .basal-col,
    .trees-col {
      min-width: 55px;
    }
  }

  #activities-info-wrapper {
    .form-control, a {
      padding-left: 3px;
      padding-right: 3px;
    }

    .pieces-input {
      min-width: 45px;
    }

    .species-input {
      min-width: 78px;
    }
  }
}

.comment-col {
  width: 365px;
}

.year-col {
  width: 95px;
}

.id-col {
  width: 110px;
}

.necessary-col {
  width: 165px;
}

.type-col {
  width: 270px;
}

.activity-col {
  width: 200px;
}

.criteria-col {
  width: 170px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .criteria-choice {
    min-width: 150px;
  }
}

.activation-year-choice {
  min-width: 90px;
}

.year-input,
.activation-rule-choice {
  min-width: 80px;
}

.manner-choice,
.status-choice,
.identifier-input {
  min-width: 100px;
}

.type-choice,
.activity-choice {
  min-width: 150px;
}

.tr-hover {
  background: #f5f5f5 !important;
}

#plot_info_notes {
  min-height: 55px;
  height: 100%;
}
